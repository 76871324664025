import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { userToken } from "../../features/user";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import {Trans,  useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";

import { useSearchParams } from "react-router-dom";

import {getLang} from "../../Helper"
import Frame from '../widgets/Frame';

export function CreatePassword() {
    const [state, setState] = useState({password: "", confirmPassword: "", errors: {} });

    const reseller = useSelector((state) => {
        return state.reseller.value
    });

    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    //ce2144fa8c5f727c39b1ce80b014933fa3086e1c68dd536afa

    useEffect(()=>{
        if(!searchParams.get("hash")){
            navigate('/reset-password')
        }
        // eslint-disable-next-line
    },[]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validateForm()) {
            let errorMessage = DEFAULT_ERROR_TEXT;

            let config = {'headers': {"token": userToken(), "lang": getLang()}};
            let resellerNick = reseller.code;
            if(resellerNick && (typeof resellerNick !== "undefined")){
                config['headers']['reseller-nick'] = resellerNick;
            }
          
            const data = {hash: searchParams.get("hash"), password: state.password.trim()};
        
            try {
                const reply = await axios.post(API_URL + '/resetPassword', data, config);
                //const reply = {data: {responseStatus : API_EXCEPTION, message: 'HEEEEEEELLLLP'}};
                console.log(reply);
                if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK){
                    navigate("/password-reset");
                }else{
                    if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                        errorMessage = reply.data["message"];
                    }
                    setState({
                        ...state,
                        errors: {server_error: errorMessage}
                    });
                };
            } catch (e) {
                setState({
                    ...state,
                    errors: {server_error: errorMessage}
                });
            }
        }
    };

    const handleChange = (e) => {
        delete state.errors[e.target.id];
        setState({
            ...state,
            [e.target.id]: e.target.value,
            errors: {...state.errors},
        });
    };

    const validateForm = () => {
        const errors = {};

        if (!state.password) {
             errors['password'] = t('Password is required');
        } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/.test(state.password)) {
             errors['password'] = t('The password you selected does not meet our security requirements');
        }

        if (state.confirmPassword !== state.password) {
             errors['confirmPassword'] = t('Passwords do not match');
        }

        setState({
            ...state,
            errors: errors,
        });

        return Object.keys(errors).length > 0;
    };

    // const hasErrors = () => {
    //     return Object.keys(state.errors).length > 0
    // }

    const toggleShowPassword = (e) => {
        if(e.target.parentElement.querySelector('input')) {
            e.target.parentElement.querySelector('input').type = e.target.parentElement.querySelector('input').type === "password"?"text":"password";
        }
    };


    return (
            <Frame>
                <div className='main main-create-password'>
                    <div className='main-top'>
                        <h1>{t("Create a new password")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <form onSubmit={handleSubmit}> 
                                <h2>{t("Please create a strong password for a maximal account protection")}</h2>
                                <h2 className='list-item'>{t("Minimum 8 characters")}</h2>
                                <h2 className='list-item'>{t("Capital and lowercase letters")}</h2>
                                <h2 className='list-item'>{t("At least one number")}</h2>
                                <h2 className='list-item'>{t("At least one special character")}</h2>
                                <div className='mt30 input blue-border'><input className={state.errors.password?"error":""} required type="password" id="password" placeholder={t("Choose a password")} onChange={(e) => handleChange(e)} /><img src='/images/show-password-icon.png' className='input-icon ml20' onClick={(e) => toggleShowPassword(e)} alt=''/></div>
                                <div className='input blue-border'><input className={state.errors.confirmPassword?"error":""} required type="password" id="confirmPassword" placeholder={t("Please repeat the password")} onChange={(e) => handleChange(e)} /><img src='/images/show-password-icon.png' className='input-icon ml20' onClick={(e) => toggleShowPassword(e)} alt=''/></div>
                                <div className='button button-space-between button-teal' onClick={(e) => handleSubmit(e)}>  <div className='mr20'>{t("next")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                                {Object.entries(state.errors).map(([key, msg]) => (
                                    <h2 key={key} className='mt20 error'><Trans i18nKey={msg} /></h2>
                                ))}
                            </form>
                        </div>
                        <div className='left-main'>
                            <img src='/images/person-phone.png' className='content-logo' alt=''/>
                        </div>
                    </div>
                </div>
            </Frame>
    );
}