import { Outlet } from 'react-router-dom';
import { useSelector } from "react-redux";

const AllowSimLoginByReseller = ({ children}) => {
    const reseller = useSelector((state) => {
        return state.reseller.value
    });
    const status = useSelector((state) => state.reseller.status);

    if (status !== "succeeded" || !reseller || !reseller.hasOwnProperty("code")) {
        return <div className="loader-container"><div className="loader"></div></div>;
    }else{
        return <Outlet />;
    }
};


export default AllowSimLoginByReseller;