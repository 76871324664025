import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register, isLoggedIn, isRegister, userToken, logout } from "../../features/user";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import {Trans,  useTranslation } from 'react-i18next';
import {checkApiSessionErrorMessage, checkValidToken, getLang} from "../../Helper"

import Frame from '../widgets/Frame';

export function Register() {
    const [state, setState] = useState({firstName:"", lastName:"", idType:"ID", idNumber:"", phoneCode:"+972", phoneNumber:"", communicationPref:"SMS", email: "", errors:{}, showIdDropdown: false, showPhoneCodeDropdown: false });

    const reseller = useSelector((state) => {
        return state.reseller.value
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const isUserLoggedIn = useSelector(isLoggedIn);
    const isUserRegister = useSelector(isRegister);


    const phoneCodes = {'+972':'israel', '+1':'usa', '+44':'uk', '+33':'france', '+52':'mexico', '+54':'argentina', '+56':'chile', '+971':'uae', '+47':'norway'}

    useEffect(()=>{
        if(isUserLoggedIn){
            checkValidToken(navigate, dispatch, logout);

            /*if(searchParams.get("redirect")) {
                window.location.replace(searchParams.get("redirect"));
            } else {
                navigate('/successfully-logged-in');
            }*/
        }
        if(isUserRegister){
            getRegistrationData();
        }
        // eslint-disable-next-line
    },[]);

    const getRegistrationData = async (e) => {
        let errorMessage = DEFAULT_ERROR_TEXT;

        let config = isUserRegister?{'headers': {"token": userToken(), "lang": getLang()}}:{'headers': {"lang": getLang()}};
        let resellerNick = reseller.code;
        if(resellerNick && (typeof resellerNick !== "undefined")){
            config['headers']['reseller-nick'] = resellerNick;
        }
        try {
            const reply = await axios.get(API_URL + '/getRegistrationData', config);
            if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK) {
                setState({
                    ...state,
                    firstName: reply.data.data.firstName,
                    lastName: reply.data.data.lastName,
                    email: reply.data.data.email,
                    idNumber: ""+reply.data.data.validId,
                    validType: reply.data.data.idType, 
                    phoneCode: reply.data.data.phone.split(' ').length>0?reply.data.data.phone.split(' ')[0]:'',
                    phoneNumber: reply.data.data.phone.split(' ').length>1?reply.data.data.phone.split(' ')[1]:'',
                    communicationPref: reply.data.data.communicationPref
                });
            } else {
                if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                    errorMessage = reply.data["message"];
                }
                if(!checkApiSessionErrorMessage(errorMessage, dispatch, logout, navigate)) {
                    setState({
                        ...state,
                        errors: {server_error: errorMessage}
                    });
                }
            }
        } catch (e) {
            setState({
                ...state,
                errors: {server_error: errorMessage}
            });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validateForm()) {
            let errorMessage = DEFAULT_ERROR_TEXT;
            
            let config = isUserRegister?{'headers': {"token": userToken(), "lang": getLang()}}:{'headers': {"lang": getLang()}};
            let resellerNick = reseller.code;
            if(resellerNick && (typeof resellerNick !== "undefined")){
                config['headers']['reseller-nick'] = resellerNick;
            }
          
            const data = {firstName: state.firstName.trim(), lastName: state.lastName.trim(), email: state.email.trim(), validId: +state.idNumber.trim(), validType: state.idType, phone: state.phoneCode+' '+state.phoneNumber.trim(), communicationPref: state.communicationPref};
        
            try {
                const reply = await axios.post(API_URL + '/registerNewUser', data, config);
                //const reply = {data: {responseStatus : API_EXCEPTION, message: 'HEEEEEEELLLLP'}};
                console.log(reply);
                if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK
                        && reply.data.hasOwnProperty('token')){
                    dispatch(register({token : reply.data.token}));
                    navigate("/verify-email");
                }else{
                    if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                        errorMessage = reply.data["message"];
                    }
                    if(!checkApiSessionErrorMessage(errorMessage, dispatch, logout, navigate)) {
                        setState({
                            ...state,
                            errors: {server_error: errorMessage}
                        });
                    }
                };
            } catch (e) {
                setState({
                    ...state,
                    errors: {server_error: errorMessage}
                });
            }
        }

    };

    const handleChange = (e) => {
        delete state.errors[e.target.id];
        setState({
            ...state,
            [e.target.id]: e.target.value,
            errors: {...state.errors},
        });
    };


    const toggleCommunicationPref = (e) => {
        setState({
            ...state,
            communicationPref: state.communicationPref === 'SMS'?'VOICE':'SMS',
        });
    };


    const toggleShowIdDropdown = (e) => {
        setState({
            ...state,
            showIdDropdown: !state.showIdDropdown,
        });
    };

    const setIdType = (idType) => {
        setState({
            ...state,
            idType: idType,
            showIdDropdown: false,
        });
    };

    const toggleShowPhoneCodeDropdown = (e) => {
        setState({
            ...state,
            showPhoneCodeDropdown: !state.showPhoneCodeDropdown,
        });
    };

    const setPhoneCode = (phoneCode) => {
        setState({
            ...state,
            phoneCode: phoneCode,
            showPhoneCodeDropdown: false,
        });
    };

    const validateForm = () => {
        const errors = {};
        if (!state.firstName.trim() /*|| state.firstName.trim().length < 4*/) {
            errors['firstName'] = 'First Name is required';
        }

        if (!state.lastName.trim() /*|| state.firstName.trim().length < 4*/) {
            errors['lastName'] = 'Last Name is required';
        }

        if (!state.email.trim()) {
            errors['email'] = t('Email is required');
        } else if (!/\S+@\S+\.\S+/.test(state.email)) {
            errors['email'] = 'Email is invalid';
        }

        if (!state.idNumber.trim()) {
            errors['idNumber'] = t(state.idType+' Number is required');
        } else if((state.idType === 'ID' || state.idType === 'Passport') && !/^(?=(?:[^0-9]*[0-9]){9})[\d -]*$/.test(state.idNumber)) {
            errors['idNumber'] = state.idType+' Number is invalid';
        }

        if (!state.phoneNumber.trim()) {
            errors['phoneNumber'] = 'Phone Number is required';
        } else if (!/^(?=(?:[^0-9]*[0-9]){7})[\d -]*$/.test(state.phoneNumber)) {
            errors['phoneNumber'] = 'Phone number is invalid';
        }

        setState({
            ...state,
            errors: errors,
        });

        return Object.keys(errors).length > 0;
    };


    /*const hasErrors = () => {
        return Object.keys(state.errors).length > 0;
    }*/


    return (
            <Frame>
                <div className='main main-register'>
                    <div className='main-top'>
                        <h1>{t("Create a new account")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <form onSubmit={handleSubmit}> 
                                <h2>{t("We are excited to welcome you to the Yemot Hamashiach family.")}</h2>
                                <h2>{t("Please insert the following details")}</h2>
                                <h2><Trans i18nKey="Existing customer? <strong>Please login</strong>" /></h2>
                                <div className='mt30 input blue-border'> <input className={state.errors.firstName?"error":""} type="text" id="firstName" value={state.firstName} placeholder={t("First name")} onChange={(e) => handleChange(e)} /></div>
                                <div className='input blue-border'> <input className={state.errors.lastName?"error":""} type="text" id="lastName" value={state.lastName} placeholder={t("Last name")} onChange={(e) => handleChange(e)} /></div>

                                <div className='input blue-border relative'>
                                    <input className={state.errors.idNumber?"error":""} type="text" id="idNumber" value={state.idNumber} placeholder={t(state.idType+' number')} onChange={(e) => handleChange(e)} />
                                    <div className='open-dropdown-icon-div open-id-type-dropdown' onClick={(e) => toggleShowIdDropdown(e)}><img src='/images/open-dropdown-icon.png' className='open-dropdown-icon' alt=''/></div>
                                    {state.showIdDropdown?<div className="dropdown id-type-dropdown  blue-border">
                                        <div className='item' onClick={(e) => setIdType("ID")}>{t("ID number")}</div>
                                        <div className='item' onClick={(e) => setIdType("Passport")}>{t("Passport number")}</div>
                                        <div className='item' onClick={(e) => setIdType("Company")}>{t("Company number")}</div>
                                    </div>:<></>}
                                </div>

                                <div className='phone-number'>
                                    <div className='ml15 input blue-border'>
                                        <input className={state.errors.phoneNumber?"error":""} type="tel" id="phoneNumber" value={state.phoneNumber} pattern="[\d\s\-]{5,10}" placeholder={t("Mobile phone number")} onChange={(e) => handleChange(e)} />
                                    </div>

                                    <div className='input blue-border relative phone-code-input'>
                                        <div className='phone-code mr10'><div className='ltr'>{state.phoneCode}</div> <img src={'/images/flags/flag-'+phoneCodes[state.phoneCode]+'.png'} className='flag-icon' alt=''/> </div>
                                        <div className='open-dropdown-icon-div open-phone-code-dropdown' onClick={(e) => toggleShowPhoneCodeDropdown(e)}><img src='/images/open-dropdown-icon.png' className='open-dropdown-icon' alt=''/></div>
                                        {state.showPhoneCodeDropdown?<div className="dropdown phone-code-dropdown  blue-border">
                                            {Object.entries(phoneCodes).map(([code, name]) => (
                                                <div key={code} className='item' onClick={(e) => setPhoneCode(code)}>
                                                    <div>{t(name)}</div>
                                                    <div className='phone-code'><img src={'/images/flags/flag-'+name+'.png'} className='flag-icon' alt=''/> <div className='ltr'>{code}</div> </div>
                                                </div>
                                            ))}
                                        </div>:<></>}
                                    </div>
                                </div>

                                <div className='input blue-border'> <input className={state.errors.email?"error":""} type="email" inputMode="email" id="email" value={state.email} placeholder={t("Email address")} onChange={(e) => handleChange(e)} /></div>

                                <h2 className='teal mt30'>{t("How do you prefer to communicate?")}</h2>
                                <div className='contact-type'>
                                    <h2 className='teal ml20'>{t("Voice call")}</h2>
                                    <div className={state.communicationPref==='SMS'?'switch switch-selected':'switch'} onClick={(e) => toggleCommunicationPref(e)}>
                                        <div className='switch-item'></div>
                                    </div>
                                    <h2 className='teal mr30'>{t("sms")}</h2>
                                </div>

                                <div className='button button-space-between button-teal' onClick={(e) => handleSubmit(e)}>  <div className='mr20'>{t("next")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>

                                {Object.entries(state.errors).map(([key, msg]) => (
                                    <h2 key={key} className='mt20 error'><Trans i18nKey={msg} /></h2>
                                ))}
                            </form>
                        </div>

                        <div className='left-main'>
                            <img src='/images/person-pointing.png' className='content-logo' alt=''/>
                        </div>
                    </div>
                </div>
            </Frame>
    );
}