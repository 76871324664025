import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import cookie from "js-cookie";
import axios from "axios";
import { API_URL } from '../Constants';


export const fetchReseller = createAsyncThunk(
  "reseller/fetchReseller",
  async (_, { rejectWithValue }) => {
    try {
      const cachedResellerData = cookie.get("resellerData");

      if (cachedResellerData) {
        return JSON.parse(cachedResellerData);
      }

      const response = await axios.get(API_URL + "/getResellerData");
      if(response?.data?.responseStatus === "OK"){
        cookie.set("resellerData", JSON.stringify(response.data.resellerData), { 
          expires: 1 / 24, 
          path: "/",
          secure: true,
          sameSite: "Lax"
        });
  
        return response.data.resellerData;
      }else{
        throw new Error("Error");
      }
    } catch (error) {
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const resellerSlice = createSlice({
  name: "reseller",
  initialState: () => {
    return {
      value: null,
      status: "idle",
      error: null,
    }
  },
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchReseller.pending, (state) => {
        state.status = "loading"; 
      })
      .addCase(fetchReseller.fulfilled, (state, action) => {
        state.value = action.payload; // Store the fetched reseller
        state.status = "succeeded";
      })
      .addCase(fetchReseller.rejected, (state, action) => {
        state.error = action.error.message; // Store the error message
        state.status = "failed";
      });
  },
});

export default resellerSlice.reducer;
