import cookie from "js-cookie";
import queryString from 'query-string';
import { useLocation, Outlet } from 'react-router-dom';

const HanldeURLParameters = ({}) => {
    const location = useLocation();
    const { hash, pathname, search } = location;
    const params = queryString.parse(search);
    const redirectFrom = params?.redirect_from;

    if(redirectFrom){
        const hostRegex = /^(?:https?:\/\/)?(?:www\.)?([^\/:]+)/;
        const match = redirectFrom.match(hostRegex);
        const hostname = match ? match[1] : null;

        if(hostname !== null){
            cookie.set("redirect_from",redirectFrom);
        }
    }

    return <Outlet />;
    
};


export default HanldeURLParameters;