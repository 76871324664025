import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isPending2FA, userToken, login, logout } from "../../features/user";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import {Trans,  useTranslation } from 'react-i18next';
import cookie from "js-cookie";
import {checkApiSessionErrorMessage, displayMinutes, getLang} from "../../Helper"

import Frame from '../widgets/Frame';

export function LoginVerify() {
    const [state, setState] = useState({ code: "", errors: {}, phone:"" });

    const reseller = useSelector((state) => {
        return state.reseller.value
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isUserPending2FA = useSelector(isPending2FA);

    useEffect(()=>{
        if(!isUserPending2FA){
            navigate("/login");
        } else {
            getPhoneData();
        }
        // eslint-disable-next-line
    },[]);

    const getPhoneData = async (e) => {
        let errorMessage = DEFAULT_ERROR_TEXT;

        let config = isUserPending2FA?{'headers': {"token": userToken(), "lang": getLang()}}:{'headers': {"lang": getLang()}};
        let resellerNick = reseller.code;
        if(resellerNick && (typeof resellerNick !== "undefined")){
            config['headers']['reseller-nick'] = resellerNick;
        }
        try {
            const reply = await axios.get(API_URL + '/get2faPhone', config);
            if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK) {
                setState({
                    ...state,
                    phone: reply.data.phoneNumber	,
                });
            } else {
                if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                    errorMessage = reply.data["message"];
                }
                if(!checkApiSessionErrorMessage(errorMessage, dispatch, logout, navigate)) {
                    setState({
                        ...state,
                        errors: {server_error: errorMessage}
                    });
                }
            }
        } catch (e) {
            setState({
                ...state,
                errors: {server_error: errorMessage}
            });
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!validateForm()) {
            let errorMessage = DEFAULT_ERROR_TEXT;

            let config = {'headers': {"token": userToken(), "lang": getLang()}};
            let resellerNick = reseller.code;
            if(resellerNick && (typeof resellerNick !== "undefined")){
                config['headers']['reseller-nick'] = resellerNick;
            }
          
            const data = {code: state.code.trim()};
        
            try {
                const reply = await axios.post(API_URL + '/check2faCode', data, config);
                //const reply = {data: {responseStatus : API_EXCEPTION, message: 'HEEEEEEELLLLP'}};
                console.log(reply);
                if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK
                        && reply.data.hasOwnProperty('token')){
                    dispatch(login({token : reply.data.token}));

                    let redirectFrom = cookie.get('redirect_from');
                    if(redirectFrom){
                        window.location.href = redirectFrom+"?token="+reply.data.token;
                    } else {
                        navigate("/successfully-logged-in");
                    }
                }else{
                    if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                        errorMessage = reply.data["message"];
                    }
                    if(!checkApiSessionErrorMessage(errorMessage, dispatch, logout, navigate)) {
                        let errors = {server_error: errorMessage};

                        if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("tryAgainInMinutes"))){
                            errors = {...errors, tryAgainInMinutes: t('Please try again in {{minutes}} minutes', {minutes: displayMinutes(reply.data["tryAgainInMinutes"])})}
                        }
    
                        setState({
                            ...state,
                            errors: errors
                        });
                    }
                };
            } catch (e) {
                setState({
                    ...state,
                    errors: {server_error: errorMessage}
                });
            }
        }

    };

    const handleResendCode = async (e) => {
        e.preventDefault();
        navigate("/verification-2fa-code-type");
    };

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value,
            errors: {},
            // message: null
        });
    };


    const validateForm = () => {
        const errors = {};
        if (!state.code.trim()) {
            errors['code'] = 'Verification Code is required';
        }

        setState({
            ...state,
            errors: errors,
            // message: null
        });

        return Object.keys(errors).length > 0;
    };


    return (
            <Frame>
                <div className='main main-login-verify'>
                    <div className='main-top'>
                        <h1>{t("Two-factor authentication")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <div className='right-main-inner'>
                                <h2>{t("We just want to make sure that it's really you!")}</h2>
                                <h2><Trans i18nKey="The verification code was sent to - <span>{{phone}}</span>"
                                    values={{ phone: state.phone}}
                                    components={{
                                        span: <span className="ltr-span" />
                                    }}
                                /></h2>
                                <h2>{t("Please insert it here")}</h2>
                                <div className='mt30 input blue-border'><input className={state.errors.code?"error":""}  type="text" id="code" placeholder={t("Verification Code")} onChange={(e) => handleChange(e)} /></div>
                                <div className='button button-space-between button-teal' onClick={(e) => handleSubmit(e)}>  <div className='mr20'>{t("next")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                                <h2 className='mt40 teal pointer' onClick={(e) => handleResendCode(e)}><Trans i18nKey="I didn't receive the code, <strong>please resend it</strong>" /></h2>
                                {Object.entries(state.errors).map(([key, msg]) => (
                                    <h2 key={key} className='mt20 error'><Trans i18nKey={msg} /></h2>
                                ))}
                            </div>
                        </div>
                        <div className='left-main'>
                            <img src='/images/person-phone.png' className='content-logo' alt=''/>
                        </div>
                    </div>
                </div>
            </Frame>
    );
}