import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isRegister, userToken, logout } from "../../features/user";
import axios from "axios";
import { API_OK, API_URL, DEFAULT_ERROR_TEXT, API_EXCEPTION } from '../../Constants';
import {Trans,  useTranslation } from 'react-i18next';
import {checkApiSessionErrorMessage, delay, displayMinutes, getLang} from "../../Helper"

import Frame from '../widgets/Frame';

export function VerificationPhoneCodeType() {
    const [state, setState] = useState({ errors: {}, message: null  });

    const reseller = useSelector((state) => {
        return state.reseller.value
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const isUserRegister = useSelector(isRegister);

    useEffect(()=>{
        if(!isUserRegister){
           navigate("/register");
        }
        // eslint-disable-next-line
    },[]);

    const handleResendCode = async (e, communicationPref) => {
        e.preventDefault();
        let errorMessage = DEFAULT_ERROR_TEXT;

        let config = {'headers': {"token": userToken(), "lang": getLang()}};
        let resellerNick = reseller.code;
        if(resellerNick && (typeof resellerNick !== "undefined")){
            config['headers']['reseller-nick'] = resellerNick;
        }

        const data = {communicationPref: communicationPref};
    
        try {
            const reply = await axios.post(API_URL + '/sendPhoneVerificationCode', data, config);
            if(reply.hasOwnProperty('data') && reply.data.hasOwnProperty('responseStatus') && reply.data.responseStatus === API_OK){
                //show message sent
                setState({
                    ...state,
                    errors: {},
                    message: "Phone verification code sent"
                });
                await delay(1000);
                navigate('/verify-phone');

            }else{
                if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("message"))){
                    errorMessage = reply.data["message"];
                }
                if(!checkApiSessionErrorMessage(errorMessage, dispatch, logout, navigate)) {
                    let errors = {server_error: errorMessage};

                    if((reply.data.responseStatus === API_EXCEPTION) && (reply.data.hasOwnProperty("tryAgainInMinutes"))){
                        errors = {...errors, tryAgainInMinutes: t('Please try again in {{minutes}} minutes', {minutes: displayMinutes(reply.data["tryAgainInMinutes"])})}
                    }

                    setState({
                        ...state,
                        errors: errors,
                        message: null
                    });
                }

            };
        } catch (e) {
            setState({
                ...state,
                errors: {server_error: errorMessage},
                message: null
            });
        }
    };

    const editPhone = (e) => {
        e.preventDefault();
        navigate("/register");
    };


    return (
            <Frame>
                <div className='main main-verification-code-type'>
                    <div className='main-top'>
                        <h1>{t("Problem receiving the verification code")}</h1>
                    </div>
                    <div className='main-body'>
                        <div className='right-main'>
                            <div className='right-main-inner'>
                                <h2>{t("We are sorry for the inconvenience. Here are a few options that can help")}</h2>
                                <div className='button button-space-between button-teal' onClick={(e) => handleResendCode(e,'SMS')}>  <div className='mr20'>{t("Please send me a new code via SMS")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                                <div className='button button-space-between button-teal' onClick={(e) => handleResendCode(e, 'VOICE')}>  <div className='mr20'>{t("Please send me a new code via phone call")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                                <div className='button button-space-between button-teal' onClick={(e) => editPhone(e)}>  <div className='mr20'>{t("Update my phone number")}</div> <img src='/images/less-than-white.png' className='ml20 button-icon less-than-icon' alt=''/> </div>
                                {state.message?<h2 className='teal mt10'><Trans i18nKey={state.message} /></h2>:""}
                                {Object.entries(state.errors).map(([key, msg]) => (
                                    <h2 key={key} className='mt20 error'><Trans i18nKey={msg} /></h2>
                                ))}
                                <h2 className='mt50 teal'><Trans i18nKey="If you are still experiencing issues, please contact our support via phone number 077-2222-770 or via the email cs@yemot.co.il." /></h2>
                                {/* <h2 className='mt50 teal'><Trans i18nKey="l6" values={{ email: 'email', phoneNumber: 'phoneNumber' }}/></h2> */}
                                {/* {state.error?<h2 className='mt40 error'><Trans i18nKey="f1" /></h2>:""}
                                {state.error?<h2><Trans i18nKey="f2" /></h2>:""} */}
                            </div>
                        </div>    
                        <div className='left-main'>
                            <img src='/images/person-magnifying-glass.png' className='content-logo' alt=''/>
                        </div>
                    </div>
                </div>
            </Frame>
    );
}